@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

.container-fluid {
  max-height: 85vh; /* ou outro valor que se ajuste ao seu layout */
  overflow-y: auto; /* Adiciona a barra de rolagem vertical */
}

.ngx-direita {
  text-align: end !important;
}

.ngx-datatable,
.ngx-datatable.bootstrap {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  border-radius: 8px;
}

.ngx-datatable .datatable-header,
.ngx-datatable .datatable-footer .datatable-footer-inner,
.ngx-datatable.bootstrap .datatable-header,
.ngx-datatable.bootstrap .datatable-footer .datatable-footer-inner {
  background-color: #7A7A7A !important;
  color: #fff;
}

.ngx-datatable .datatable-header,
.ngx-datatable.bootstrap .datatable-header {
  border-top-style: solid !important;
  border-top-color: #7A7A7A !important;
  border-top-width: 0.05px !important;
  color: #fff;
}

.ngx-datatable .datatable-footer,
.ngx-datatable.bootstrap .datatable-footer {
  border-style: solid !important;
  border-color: #7A7A7A !important;
  border-width: 0.05px 0.05px 0.05px 0.05px !important;
  margin-top: -5px !important;
}

.ngx-datatable .datatable-header-cell,
.ngx-datatable .datatable-body-cell,
.ngx-datatable.bootstrap .datatable-header-cell,
.ngx-datatable.bootstrap .datatable-body-cell {
  border-style: solid !important;
  border-color: #7A7A7A !important;
  border-width: 0 0.05px 0 0 !important;
  white-space: break-spaces !important;
  word-break: break-word !important;
}

.ngx-datatable .datatable-header-cell:first-child,
.ngx-datatable .datatable-body-cell:first-child,
.ngx-datatable.bootstrap .datatable-header-cell:first-child,
.ngx-datatable.bootstrap .datatable-body-cell:first-child {
  border-left-style: solid !important;
  border-left-color: #7A7A7A !important;
  border-left-width: 0.05px !important;
}

.ngx-datatable.bootstrap.fixed-row .datatable-scroll .datatable-body-row:hover,
.ngx-datatable.bootstrap .datatable-body .datatable-row-wrapper:hover,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row:hover,
.ngx-datatable .datatable-body .datatable-row-wrapper:hover {
  background-color: #E8E8E8 !important;
}

.ngx-datatable.bootstrap.fixed-row .datatable-scroll .datatable-body-row .ngx-datatable-row-detail_Aparencia:hover,
.ngx-datatable.bootstrap .datatable-body .datatable-row-wrapper  .ngx-datatable-row-detail_Aparencia:hover,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .ngx-datatable-row-detail_Aparencia:hover,
.ngx-datatable .datatable-body .datatable-row-wrapper .ngx-datatable-row-detail_Aparencia:hover {
  background-color: #fff !important;
}

.ngx-datatable .datatable-header .datatable-header-cell,
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  padding: 0.5rem !important;
}


.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-label,
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn,
.ngx-datatable .datatable-footer,
.ngx-datatable .datatable-footer .datatable-pager a,
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label,
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .sort-btn,
.ngx-datatable.bootstrap .datatable-footer,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager a,
.ngx-datatable-cabecalho {
  color: #fff !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a,
.ngx-datatable .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #3E3E3E !important;
}

.ngx-datatable .datatable-footer .page-count {
  flex: 1 1 40% !important;
}

.datatable-icon-prev::before {
  font-family: FontAwesome !important;
  content: '\f100' !important;
}

.datatable-icon-left::before {
  font-family: FontAwesome !important;
  content: '\f104' !important;
}

.datatable-icon-right::before {
  font-family: FontAwesome !important;
  content: '\f105' !important;
}

.datatable-icon-skip::before {
  font-family: FontAwesome !important;
  content: '\f101' !important;
}

.datatable-icon-sort-unset:before {
  opacity: 1;
}

.ngx-datatable .datatable-body .datatable-body-row.active,
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #7A7A7A !important;
}

.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell,
.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.4rem !important;
}

.ngx-datatable .datatable-body .datatable-scroll,
.ngx-datatable.bootstrap .datatable-body .datatable-scroll {
  width: 100% !important;
}

.ngx-datatable .datatable-body .datatable-scroll.ng-star-inserted,
.ngx-datatable.bootstrap .datatable-body .datatable-scroll.ng-star-inserted {
  width: 100% !important;
}

.ngx-datatable .datatable-body .datatable-row-detail,
.ngx-datatable.bootstrap .datatable-body .datatable-row-detail {
  height: auto !important;
}

.ngx-datatable .datatable-body .datatable-row-detail.ng-star-inserted,
.ngx-datatable.bootstrap .datatable-body .datatable-row-detail.ng-star-inserted {
  height: auto !important;
}

.ngx-datatable .datatable-body .datatable-row-detail.ng-star-inserted fieldset,
.ngx-datatable.bootstrap .datatable-body .datatable-row-detail.ng-star-inserted fieldset {
  height: auto !important;
}

.ngx-datatable a,
.ngx-datatable.bootstrap a {
  color: #000 !important;
}

.ngx-dropdown-container .ngx-dropdown-button {
  padding: 8px 10px;
}

/*responsive ngx-datatable inicio */
@media screen and (max-width: 800px) {
  .desktop-hidden {
    display: initial;
  }

  .mobile-hidden {
    display: none;
  }

  .ngx-datatable .datatable-footer .datatable-footer-inner,
  .ngx-datatable.bootstrap .datatable-footer .datatable-footer-inner {
    height: 50px !important;
  }
}

@media screen and (min-width: 800px) {
  .desktop-hidden {
    display: none;
  }

  .mobile-hidden {
    display: initial;
  }
}

@media screen and (max-width: 600px) {

  .ngx-datatable .datatable-footer .datatable-footer-inner,
  .ngx-datatable.bootstrap .datatable-footer .datatable-footer-inner {
    height: 65px !important;
  }

  .ngx-datatable.bootstrap .datatable-footer .page-count {
    line-height: 15px;
    padding: 0 0.4rem;
  }

  .ngx-datatable,
  .ngx-datatable.bootstrap {
    width: 100% !important;
  }
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x) 0 var(--bs-offcanvas-padding-y) !important;
}

/* aparencia do tooltip */
.customClass .tooltip-inner {
  background: #002f27;
}

.customClass .tooltip-arrow.arrow::before {
  border-top-color: #002f27;
}

.card-title-form {
  color: #000;
  padding: 10px 10px 10px 10px;
  margin-top: .4rem;
  margin-bottom: .4rem;
  font-size: 1.3rem !important;
  font-weight: bold !important;
  font-family: 'Lato', sans-serif !important;
  background-color: #E8E8E8;
  width: 100% !important;
  border-radius: 8px;
}

.card-title-form-menor {
  color: #000;
  padding: 10px 10px 10px 0px;
  margin-top: .2rem;
  margin-bottom: .2rem;
  font-size: 1.1rem !important;
  font-weight: bold !important;
  font-family: 'Lato', sans-serif !important;
  background-color: #E8E8E8;
  width: 100% !important;
  border-radius: 8px;
}

.card-title-form-menor-FB {
  color: #000;
  padding: 10px 10px 10px 0px;
  font-size: 1.1rem !important;
  font-weight: bold !important;
  font-family: 'Lato', sans-serif !important;
  text-decoration: underline;
  background-color: #fff;
  width: 100% !important;
  border-radius: 8px;
}

.card-title-form-texto {
  font-weight: bold !important;
  background-color: #fff !important;
  width: 100% !important;
}


.card-body-form {
  padding: 0;
  margin: 0.3% 0 0.5% 0;
  width: 100% !important;
}

.top1 {
  margin-top: 1%;
}

.top02 {
  margin-top: 0.2%;
}

.bottom1 {
  margin-bottom: 1%;
}

.bottom02 {
  margin-bottom: 0.2%;
}

.iconeTamanho {
  font-size: 1.3rem !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.card-title-form-icone {
  font-size: 1.5rem !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.excell-title-form-icone {
    font-size: 1.2rem !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

.customSearchButtons {
  border-style: none;
  background-color: transparent;
  margin-right: 0rem !important;
  margin-left: 0.5rem !important;
  font-size: 1.3rem !important;
}

.icone-para-botao {
  font-size: 0.8rem !important;
  padding: 0 !important;
  margin-right: 0.5em !important;
}

.linhaSuperior {
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: inherit;
  margin-top: 1%;
  padding-top: 1%;
}

.linha-de-botoes {
  padding: 0;
  margin: 0.5% 0 0.5% 0;
  width: 100% !important;
  text-align: end !important;
  display: block;
  /* padding-inline-end: 1%; */
}

.linha-de-botoes button {
  margin-left: 0.3%;
  width: auto;
  padding: 4px 12px;
}

.linha-de-botoes-esquerda {
  padding: 0;
  margin: 0.75% 0 0.75% 0;
  width: 100% !important;
  align-items: flex-start;
  display: flex;
  padding-inline-start: 1%;
}

.linha-de-botoes-esquerda button {
  margin-right: 0.3%;
  width: auto;
  padding: 4px 12px;
}

.posiociona-botao-final {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  float: inline-end;
}

/* placeholder */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold;
}

.form-label {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
}

.div.w-100.a {
  text-decoration: none !important;
}

.form-control {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  text-decoration: none !important;
}

.form-control:focus {
  border-color: #FBB035 !important;
  box-shadow: none;
  background: #fff;
}

.form-control-search {
  border-radius: 8px 0 0 8px;
}

.form-control-lupa_midle {
  border:  var(--bs-border-width) solid var(--bs-border-color);
  border-left: none;
  border-radius: 0 0 0 0;
  padding: 0.33rem 0.5rem;
  margin-left: 0 !important;
}

.form-control-lupa_last {
  border:  var(--bs-border-width) solid var(--bs-border-color);
  border-left: none;
  border-radius: 0 8px 8px 0;
  padding: 0.33rem 0.5rem;
  margin-left: 0 !important;
}

.form-control-close {
  border:  var(--bs-border-width) solid var(--bs-border-color);
  border-left: none;
  border-radius: 0 8px 8px 0;
  padding: 0.33rem 0.5rem;
  margin-left: 0 !important;
}

.bg-relato {
  background-color: transparent !important;
  border-color: #FBB035 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000 !important;
  width: 32%;
  margin-left: 1%
}

.bg-relato .card-header {
  border-bottom-color: #FBB035;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
}

.bg-primary,
.bg-administrativo,
.dropdown-item {
  background-color: #FBB035 !important;
  border-color: #FBB035 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000 !important;
}

.bg-primary-ocupacao {
  background-color: #FBB035 !important;
  border-color: #FBB035 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 11px !important;
  color: #000 !important;
  padding: 0.2rem 0.2rem !important;
}

.bg-clinica {
  background-color: #7A7A7A !important;
  border-color: #7A7A7A !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FBB035 !important;
}

.bg-clinica .card-header {
  border-bottom-color: #FBB035;
}

.bg-ensino {
  background-color: #A3A3A3 !important;
  border-color: #A3A3A3 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000 !important;
}

.bg-cientifico {
  background-color: #000 !important;
  border-color: #000 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #fff !important;
}

.bg-cientifico .card-header {
  border-bottom-color: #FBB035;
}

.dropdown-item:hover {
  color: #FFF !important;
  font-weight: bold !important;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-head {
  background-color: #FBB035;
}

.theme-dark-blue .btn-today-wrapper .btn-success,
.theme-dark-blue .btn-clear-wrapper .btn-success {
  background-color: #FBB035;
  border-color: #FBB035;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #FBB035;
}

.form_control_ngx_select_dropdown {
  display: block;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: 450;
  padding: 0;
  line-height: 1.5;
  background-clip: padding-box;
  appearance: none;
  min-height: calc(1.5em + 0.5rem + 2px);
  border-radius: 8px;
}

.table-responsive-overflow-400 {
  max-height: 400px;
  margin-bottom: 0.5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-responsive-overflow-430 {
  max-height: 430px;
  margin-bottom: 0.5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-responsive-overflow-450 {
  max-height: 450px;
  margin-bottom: 0.5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-responsive-overflow-480 {
  max-height: 480px;
  margin-bottom: 0.5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-responsive-overflow-500 {
  max-height: 500px;
  margin-bottom: 0.5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-responsive-overflow-600 {
  max-height: 600px;
  margin-bottom: 0.5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-responsive-overflow-auto {
  max-height: 1000px;
  min-height: 400px;
  height: auto;
  margin-bottom: 0.5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.nav-tabs .nav-item.show .nav-link,
.navbar-expand .navbar-nav .dropdown-menu {
  background-color: #FBB035;
  border-color: #FBB035;
  color: #000;
}

.nav-tabs .nav-link {
  color: #000;
}

.nav-tabs .nav-link.active {
  color: #000;
  font-weight: bolder;
  border-style: solid !important;
  border-color: #FBB035 !important;
  border-width: 0px 0px 2px 0px !important;
}

.ngx-datatable-row-detail_Aparencia {
  margin-bottom: 1%;
  margin-top: 0.5%;
  padding-left: 0.5%;
  padding-right: 0.5%;
  overflow-x: hidden;
}

table.rounded-corners {
  /* Change these properties */
  --border: 1px solid #919191 !important;
  border-radius: 5px !important;
  margin-top: 3px !important;

  /* Don't change these properties */
  border-spacing: 0 !important;
  border-collapse: separate !important;
  border: var(--border) !important;
  overflow: hidden !important;
 }

 /* Apply a border to the right of all but the last column */
 table.rounded-corners th:not(:last-child),
 table.rounded-corners td:not(:last-child) {
  border-right: var(--border) !important;
 }

 /* Apply a border to the bottom of all but the last row */
 table.rounded-corners>thead>tr:not(:last-child)>th,
 table.rounded-corners>thead>tr:not(:last-child)>td,
 table.rounded-corners>tbody>tr:not(:last-child)>th,
 table.rounded-corners>tbody>tr:not(:last-child)>td,
 table.rounded-corners>tfoot>tr:not(:last-child)>th,
 table.rounded-corners>tfoot>tr:not(:last-child)>td,
 table.rounded-corners>tr:not(:last-child)>td,
 table.rounded-corners>tr:not(:last-child)>th,
 table.rounded-corners>thead:not(:last-child),
 table.rounded-corners>tbody:not(:last-child),
 table.rounded-corners>tfoot:not(:last-child) {
  border-bottom: var(--border) !important;
 }

.tableFont {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
}


/**   botões  **/

/* primary button */
.btn-primary {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
  color: #000;
  background-color: #FBB035;
  border-color: #FBB035;
  border-radius: 5px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:checked {
  background-color: #D3942D;
  border-color: #D3942D;
  box-shadow: none;
}

.btn-primary:disabled,
.btn-primary.is-disabled {
  background-color: #FDD696;
  border-color: #FDD696;
}

/* secondary button */
.btn-secondary {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
  color: #fff;
  background-color: #000;
  border-color: #000;
  border-radius: 5px;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:checked {
  background-color: #525252;
  border-color: #525252;
  box-shadow: none;
}

.btn-secondary:disabled,
.btn-secondary.is-disabled {
  background-color: #7A7A7A;
  border-color: #7A7A7A;
  color: #000;
}

/* success button */
.btn-success {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
  color: #fff;
  background-color: #00826d;
  border-color: #00826d;
  border-radius: 5px;
}

.btn-success:hover,
.btn-success:focus {
  background-color: #006D5C;
  border-color: #006D5C;
  box-shadow: none;
}

.btn-success:disabled,
.btn-success.is-disabled {
  background-color: #7ABEB3;
  border-color: #7ABEB3;
  color: #000;
}

/* info button */
.btn-info {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
  color: #fff;
  background-color: #067BC2;
  border-color: #067BC2;
  border-radius: 5px;
}

.btn-info:hover,
.btn-info:focus {
  background-color: #0567A3;
  border-color: #0567A3;
  box-shadow: none;
}

.btn-info:disabled,
.btn-info.is-disabled {
  background-color: #7EBADF;
  border-color: #7EBADF;
  color: #000;
}

/* warning button */
.btn-warning {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
  color: #000;
  background-color: #ADADAD;
  border-color: #ADADAD;
  border-radius: 5px;
}

.btn-warning:hover,
.btn-warning:focus {
  background-color: #919191;
  border-color: #919191;
  box-shadow: none;
}

.btn-warning:disabled,
.btn-warning.is-disabled {
  background-color: #D4D4D4;
  border-color: #D4D4D4;
}

/* danger button */
.btn-danger {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  font-weight: bold !important;
  color: #fff;
  background-color: #B20D30;
  border-color: #B20D30;
  border-radius: 5px;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: #960B28;
  border-color: #960B28;
  box-shadow: none;
}

.btn-danger:disabled,
.btn-danger.is-disabled {
  background-color: #D78193;
  border-color: #D78193;
  color: #000;
}

.text-primary {
  color: #FBB035 !important;
  font-size: 17px !important;
}

.text-secondary {
  color: #000 !important;
  font-size: 17px !important;
}

.text-secondary-disabled {
  color: #7A7A7A;
  font-size: 17px !important;
}

.text-secondary-btn11 {
  color: #000 !important;
  font-size: 1.1rem !important;
}

.text-secondary-btn11-disabled {
  color: #7A7A7A;
  font-size: 1.1rem !important;
}

.text-secondary-btn15 {
  color: #000 !important;
  font-size: 1.5rem !important;
}

.text-secondary-btn15-disabled {
  color: #7A7A7A;
  font-size: 1.5rem !important;
}

.text-success {
  color: #00826d !important;
  font-size: 17px !important;
}

.text-warning {
  color: #ADADAD !important;
  font-size: 17px !important;
}

.text-danger {
  color: #B20D30 !important;
  font-size: 17px !important;
}

.text-info {
  color: #067BC2 !important;
  font-size: 17px !important;
}

.primary {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #FBB035;
}

.primary-50 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #FEE9C6;
}

.primary-100 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #FEE3B6;
}

.primary-200 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #FDD696;
}

.primary-300 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #FCC976;
}

.primary-400 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #FCBD55;
}

.primary-500 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #FBB035;
}

.primary-600 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #D3942D;
}

.primary-700 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #AB7824;
}

.primary-800 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #835C1C;
}

.primary-900 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #5A3F13;
}

.secondary {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #000;
}

.secondary-50 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #B8B8B8;
}

.secondary-100 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #A3A3A3;
}

.secondary-200 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #7A7A7A;
}

.secondary-300 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #525252;
}

.secondary-400 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #292929;
}

.secondary-500,
.secondary-600,
.secondary-700,
.secondary-800,
.secondary-900 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #000;
}

.success {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #00826d;
}

.success-50 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #88dcd6;
}

.success-100 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #A3D2CA;
}

.success-200 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #7ABEB3;
}

.success-300 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #52AA9C;
}

.success-400 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #299684;
}

.success-500 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #00826D;
}

.success-600 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #006D5C;
}

.success-700 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #00584A;
}

.success-800 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #004439;
}

.success-900 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #002F27;
}

/*ficou o gray da paleta para o warning*/
.warning {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #ADADAD;
}

.warning-50 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #E8E8E8;
}

.warning-100 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #E1E1E1;
}

.warning-200 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #D4D4D4;
}

.warning-300 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #C7C7C7;
}

.warning-400 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #BABABA;
}

.warning-500 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #ADADAD;
}

.warning-600 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #919191;
}

.warning-700 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #fff;
  background-color: #767676;
}

.warning-800 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #fff;
  background-color: #5A5A5A;
}

.warning-900 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #fff;
  background-color: #3E3E3E;
}

.danger {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #B20D30;
}

.danger-50 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #E9BBC5;
}

.danger-100 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #E3A8B4;
}

.danger-200 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #D78193;
}

.danger-300 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #CB5A72;
}

.danger-400 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #BE3451;
}

.danger-500 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #B20D30;
}

.danger-600 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #960B28;
}

.danger-700 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #790921;
}

.danger-800 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #5D0719;
}

.danger-900 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #400511;
}

.info {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #067BC2;
}

.info-50 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #B9DAEE;
}

.info-100 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #A5CFE9;
}

.info-200 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #72BADF;
}

.info-300 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #56A5D6;
}

.info-400 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #000;
  background-color: #2E90CC;
}

.info-500 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #067BC2;
}

.info-600 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #0567A3;
}

.info-700 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #045484;
}

.info-800 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #034065;
}

.info-500 {
  font-family: 'Lato', sans-serif !important;
  font-size: 17px !important;
  color: #FFF;
  background-color: #022C46;
}


/* cards dashboard */
.panel-primary {
  border-color: #034065;
}

.panel-green {
  border-color: #004439;
}

.panel-yellow {
  border-color: #835C1C;
}

.panel-red {
  border-color: #5D0719;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  height: 85%;
}

.panel-heading {
  padding: 20px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 100%;
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #034065;
  border-color: #034065;
}

.panel-green .panel-heading {
  border-color: #004439;
  color: #fff;
  background-color: #004439;
}

.panel-yellow .panel-heading {
  border-color: #835C1C;
  color: #fff;
  background-color: #835C1C;
}

.panel-red .panel-heading {
  border-color: #5D0719;
  color: #fff;
  background-color: #5D0719;
}

.huge {
  font-size: 40px;
}

.full {
  display: block;
  width: 100%;
}

.modal-header-exclusao {
  color: #fff;
  background-color: #5D0719;
}
